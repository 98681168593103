<template>
  <div class="about-company">
    <p>
      武汉智多蛙教育科技有限公司成立于2019年，旗下拥有一批具有丰富教学经验的研发团队和IT行业精英，致力于通过物联网支持的产品，如图形化、拖拽式编程、python、硬件、3D打印技术、PBL项目式程序、STEAM、代码编程、机器人编程、人工智能AI等，自主研发适合4~16岁孩子学习的编程课程；并采用“互联网+”线上线下相融合的教育模式达到最佳教学效果，培养孩子形成编程思维，拥有创新能力，提升数学等学科的学习能力，从而成为数字时代需要的创新型人才。
    </p>
    <p class="tx-c tx-img">
      <img src="../../assets/image/home_module_two.png"/>
    </p>

    <p class="tx-c fw-b" style="margin:20px auto 25px;font-size:20px;">
      公司课程体系
    </p>
  </div>
</template>

<script>
export default {
  components: {

  }
}
</script>

<style lang="scss">
.about-company {
  .tx-img {
    margin-top: 60px;
    
    img {
      width:600px;
      height:414px;
    }
    
  }
}
</style>